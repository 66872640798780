// Based on https://github.com/Heydon/on-demand-live-region/blob/master/on-demand-live-region.js
import React, { useCallback, useMemo, useRef, useState } from 'react'

interface IOptions {
  level?: 'polite' | 'assertive' | 'off'
  delay?: number
  removeAfterDelay?: number
}

interface IDefaultOptions {
  level: 'polite'
  delay: number
  removeAfterDelay: number
}

interface IUseAriaLiveAnnouncement {
  options?: IOptions
}

const DEFAULT_OPTIONS: IDefaultOptions = {
  level: 'polite',
  delay: 0,
  removeAfterDelay: 3000,
}

const styles = {
  height: 0,
  overflow: 'hidden',
}

export const useAriaLiveAnnouncement = ({
  options: userOptions,
}: IUseAriaLiveAnnouncement) => {
  const { level, delay, removeAfterDelay } = useMemo(
    () => ({ ...DEFAULT_OPTIONS, ...userOptions }),
    [userOptions]
  )
  const [liveRegion, setLiveRegion] = useState<React.ReactNode>(null)
  const liveRegionRef = useRef<HTMLDivElement | null>(null)

  const ariaAnnounce = useCallback(
    (message: string) => {
      if (liveRegionRef.current) liveRegionRef.current.textContent = ''
      if (liveRegion) setLiveRegion(null)

      setLiveRegion(() => {
        return (
          <div
            aria-live={level}
            role={level !== 'assertive' ? 'status' : 'alert'}
            id={`${Math.random()}`}
            ref={liveRegionRef}
            style={styles}
          />
        )
      })
      setTimeout(() => {
        if (liveRegionRef.current) {
          liveRegionRef.current.textContent = message
        }
      }, delay)
      setTimeout(() => {
        if (liveRegionRef.current) {
          liveRegionRef.current = null
          setLiveRegion(null)
        }
      }, delay + removeAfterDelay)
    },
    [liveRegion, delay, removeAfterDelay, level]
  )

  return { liveRegion, ariaAnnounce }
}
