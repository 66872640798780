import React from 'react'
import { COLORS } from '@borrowell/bw-styles'

interface IProps {
  size: number
  color: string
}

export const ChevronDown: React.FC<IProps> = ({
  size = 25,
  color = COLORS.NEUTRAL.COOL['600'],
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      aria-labelledby="title"
    >
      <title id="title">Chevron down</title>
      <g
        id="Blog-Hero-Redesign-MVP---Categories"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Mobile--Default"
          transform="translate(-302.000000, -438.000000)"
          stroke={color}
          strokeWidth="1.5"
        >
          <g id="Group-9-Copy-4" transform="translate(20.000000, 383.000000)">
            <polyline id="Stroke-1" points="283 56.014 289.985 63 296.985 56" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const ChevronUp: React.FC<IProps> = ({
  size = 25,
  color = COLORS.NEUTRAL.COOL['600'],
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      aria-labelledby="title"
    >
      <title id="title">Chevron down</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Mobile-Categories-Open"
          transform="translate(-302.000000, -438.000000)"
          stroke={color}
          strokeWidth="1.5"
        >
          <g id="Group-9-Copy-5" transform="translate(20.000000, 383.000000)">
            <polyline
              id="Stroke-1"
              transform="translate(289.992500, 59.500000) rotate(-180.000000) translate(-289.992500, -59.500000) "
              points="283 56.014 289.985 63 296.985 56"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
