import get from 'lodash/get'
import { METADATA } from '../constants'
import { Sections } from '../graphql/sections/types'
import { IMetadata } from '../graphql/sections/metadata'

export default function deriveMetadata(sectionList: Sections[]): IMetadata {
  for (const section of sectionList) {
    switch (section.__typename) {
      case 'ContentfulHero':
        return {
          __typename: 'ContentfulMetadata',
          contentful_id: `derived-from-${section.contentful_id}`,
          title: get(section, 'contentInfo.title'),
          description: { description: section.contentInfo.text?.text },
          image: section.contentInfo.image,
        }
      case 'ContentfulBlog':
        return {
          __typename: 'ContentfulMetadata',
          contentful_id: `derived-from-${section.contentful_id}`,
          title: get(section, 'title'),
          description: { description: get(section, 'text.text') },
          author: get(section, 'author'),
          image: section.image,
        }
      case 'ContentfulBlogCatalogWithCategories':
        return {
          __typename: 'ContentfulMetadata',
          contentful_id: `derived-from-${section.contentful_id}`,
          title: METADATA.BLOG_TITLE,
          author: METADATA.BORROWELL_AUTHOR,
          description: { description: METADATA.BLOG_DESCRIPTION },
          keywords: { keywords: METADATA.BLOG_KEYWORDS },
        }
    }
  }
  //If none of the above content types are present, still return {__typename: 'ContentfulMetadata'} so that the component will be rendered with default values
  return {
    __typename: 'ContentfulMetadata',
    contentful_id: 'derived-default',
  }
}
