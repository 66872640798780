import React from 'react'
import styled from '@emotion/styled'

import { COLORS, toRGBAString } from '@borrowell/bw-styles'
import mq from '../styles/breakpoints'
import { Listbox } from '@headlessui/react'
import { ChevronDown, ChevronUp } from './Icon'
import { useTheme } from '@emotion/react'
import facepaint, { BaseArg } from 'facepaint'

export interface IDropdownOption {
  value: string | number
  label: string
}

interface IDropdownProps {
  id: string
  hasError?: boolean
  errorHint?: string
  label: string
  isVisibleLabel?: boolean
  options: IDropdownOption[]
  selectedOption: IDropdownOption
  handleSelection: (option: IDropdownOption) => void
}

const highlightButtonStyles = {
  backgroundColor: toRGBAString(COLORS.PRIMARY['050'], 0.5),
}

const Container = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0',
    fontFamily: 'Lato',
  })}

  &:focus-within > label {
    color: ${COLORS.PRIMARY.DEFAULT};
  }
`

const Label = styled.label<{ hasError: boolean }>(({ hasError }) =>
  mq({
    fontFamily: 'lato',
    textAlign: 'left',
    lineHeight: ['1.33', '1.25', '1.25'],
    letterSpacing: '0.4px',
    marginTop: '5px',
    marginBottom: '5px',
    color: hasError ? COLORS.RED['700'] : COLORS.NEUTRAL.COOL['900'],
  })
)

const ErrorHint = styled.p`
  ${mq({
    fontFamily: 'lato',
    textAlign: 'left',
    margin: '0 auto 0 0',
    height: '28px',
    fontSize: ['12px', '16px', '16px'],
    fontStyle: 'italic',
    lineHeight: 1.5,
    color: COLORS.RED['700'],
  })}
`

const DropDownButton = styled.button<{
  active: boolean
  hasError?: boolean
  dropdownInputStyles: BaseArg
}>(({ active, hasError, dropdownInputStyles }) => {
  const borderStyle = hasError
    ? `1px solid ${COLORS.RED['700']}`
    : `1px solid ${COLORS.NEUTRAL.COOL['600']}`

  const common = {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0 0 0',
    cursor: 'pointer',
    textAlign: 'left',
    backgroundColor: COLORS.WHITE,
  }

  const dropdownStyles = {
    active: {
      ...dropdownInputStyles,
      ...common,
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
      border: `1px solid ${COLORS.NEUTRAL.COOL['600']}`,
      borderBottomColor: COLORS.WHITE,
      ':focus,:hover,:active': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    inactive: {
      ...dropdownInputStyles,
      ...common,
      color: COLORS.NEUTRAL.COOL['900'],
      border: borderStyle,
      ':focus,:hover,:active': {
        outline: 'none',
        boxShadow: 'none',
        border: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
      },
    },
  }

  return mq(dropdownStyles[active ? 'active' : 'inactive'])
})

const Icon = styled.div`
  ${mq({
    marginLeft: 'auto',
    paddingTop: '20px',
  })}
`

const DropdownActive = styled.div(() => {
  return mq({
    backgroundColor: COLORS.WHITE,
    margin: '0',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: 'none',
    borderTop: COLORS.PRIMARY.DEFAULT,
  })
})

const OptionButton = styled.button(
  ({
    isHighlighted,
    dropdownOptionStyles,
  }: {
    isHighlighted?: boolean
    dropdownOptionStyles?: BaseArg
  }) => {
    const buttonStyle = isHighlighted
      ? { ...dropdownOptionStyles, ...highlightButtonStyles }
      : { ...dropdownOptionStyles, fontWeight: 'normal' }

    return mq(buttonStyle)
  }
)

const NavBarToggler = ({ active }: { active: boolean }) => (
  <Icon>
    {active ? (
      <ChevronUp color={COLORS.PURPLE['800']} size={25} />
    ) : (
      <ChevronDown color={COLORS.PURPLE['800']} size={25} />
    )}
  </Icon>
)

export const Dropdown: React.FC<IDropdownProps> = ({
  id,
  hasError,
  errorHint,
  label,
  isVisibleLabel = true,
  options = [],
  selectedOption,
  handleSelection,
}) => {
  const testId = id.replace(/\s/g, '')
  const dropdownOptionStyles = useTheme().dropdownOption
  const dropdownInputStyles = useTheme().input
  return (
    <>
      <Listbox
        id={id}
        value={selectedOption}
        onChange={handleSelection}
        as={Container}
      >
        {({ open }) => (
          <>
            {isVisibleLabel && (
              <Listbox.Label as={React.Fragment}>
                <Label
                  hasError={!!hasError}
                  data-cy={`dropdown-label-${testId}`}
                >
                  {label}
                </Label>
              </Listbox.Label>
            )}
            <Listbox.Button as={React.Fragment}>
              <DropDownButton
                hasError={hasError}
                active={open}
                dropdownInputStyles={dropdownInputStyles}
                data-cy={`dropdown-button-${testId}`}
              >
                {selectedOption.label}
                <NavBarToggler active={open} />
              </DropDownButton>
            </Listbox.Button>
            <Listbox.Options as={DropdownActive}>
              {options.map((option, index) => (
                <Listbox.Option
                  key={option.value}
                  value={option}
                  as={React.Fragment}
                >
                  {({ active }) => (
                    <OptionButton
                      isHighlighted={active}
                      dropdownOptionStyles={dropdownOptionStyles}
                      data-cy={`dropdown-option-${testId}-${index}`}
                    >
                      {option.label}
                    </OptionButton>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
      {hasError && errorHint ? (
        <ErrorHint data-cy={`dropdown-error-${testId}`}>{errorHint}</ErrorHint>
      ) : null}
    </>
  )
}
