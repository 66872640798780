import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from '@emotion/styled'
import slugify from 'slugify'

import { Link } from './Link'

import { COLORS } from '@borrowell/bw-styles'
import mq from '../styles/breakpoints'
import { Heading, Markdown } from './typography'
import { IAuthorBio } from '../graphql/modules/author'
import { IImage } from '../graphql/modules/image'
import { IWithStyleOverrides } from './commonTypes'
import { useSendLinkClickedEvent } from '../hooks/analytics/useSendLinkClickedEvent'

interface ISocialLink {
  image: IImage
  link: string | undefined
  title: string | undefined
}

interface IBlogAuthorProps extends IWithStyleOverrides {
  data: IAuthorBio
  isAuthorPage?: boolean
}

type SocialImageKeys = 'externalLinkImage' | 'twitterImage' | 'linkedinImage' | 'facebookImage'

const BioContent = styled.div<IWithStyleOverrides>(({ styleOverrides }) =>
  mq({
    width: '100%',
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['flex-start'],
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderRadius: '8px',
    padding: ['25px 25px 25px 25px', '25px 25px 25px 25px', '30px 30px 30px 30px'],
    ...styleOverrides,
  }),
)
const BioImageHeader = styled(GatsbyImage)`
  ${mq({
    height: ['150px', '150px', '150px'],
    width: ['150px', '150px', '150px'],
    minWidth: ['150px', '150px', '150px'],
    background: COLORS.WHITE,
    margin: ['auto', 'auto', '9px 33px 12px 0px'],
  })}
`
const TextHeader = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    justifyContent: ['center', 'center', 'flex-start'],
    margin: ['28px auto 0px auto', '28px auto 8px auto', '0'],
    whiteSpace: ['wrap', 'nowrap', 'nowrap'],
    h5: {
      margin: '0px',
    },
  })}
`

const Separator = styled(Heading)`
  ${mq({
    display: ['none', 'block', 'block'],
  })}
`

const Content = styled.div`
  ${mq({
    width: ['100%', '100%', '435px'],
    display: 'flex',
    flexDirection: 'column',
  })}
`

const TextContent = styled.div`
  width: 100%;
  p {
    text-align: left;
    max-width: 100%;
    margin: 0px;
  }
`

const SocialButtonsSection = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    height: '22px',
    margin: '9px 0 9px 0',
    justifyContent: ['center', 'center', 'flex-start'],
  })}
`

const SocialButton = styled.a`
  ${mq({
    padding: ['0 10px 0 10px', '0 10px 0 10px', '0 20px 0 0'],
  })}
`

const SocialImage = styled(GatsbyImage)`
  ${mq({
    height: '22px',
    width: '22px',
    margin: '0',
  })}
`

const titleStyles = {
  fontSize: '19px',
  lineHeight: 1.44,
  textAlign: 'center',
}

const profilePictureStyles = {
  width: 'fit-content',
  margin: 'auto',
}

export const BlogAuthor: React.FC<
  IBlogAuthorProps & {
    socialImages: Record<SocialImageKeys, IImage>
  }
> = ({ data, styleOverrides, isAuthorPage, socialImages }) => {
  const { website, linkedIn, twitter } = data

  const socialLinks: ISocialLink[] = [
    ...(website
      ? [
          {
            title: website,
            image: socialImages.externalLinkImage,
            link: website,
          },
        ]
      : []),
    ...(twitter
      ? [
          {
            title: twitter,
            image: socialImages.twitterImage,
            link: twitter,
          },
        ]
      : []),
    ...(linkedIn
      ? [
          {
            title: linkedIn,
            image: socialImages.linkedinImage,
            link: linkedIn,
          },
        ]
      : []),
  ]

  return <Author data={data} socialLinks={socialLinks} styleOverrides={styleOverrides} isAuthorPage={isAuthorPage} />
}

export const Author: React.FC<
  IBlogAuthorProps & {
    socialLinks: ISocialLink[]
  }
> = ({ data, styleOverrides, isAuthorPage, socialLinks }) => {
  const {
    title,
    jobTitle,
    bio: { bio },
    image: {
      alternateText: { alternateText },
      image: { gatsbyImageData },
    },
    contentful_id,
    __typename,
  } = data

  const sendLinkClickedEvent = useSendLinkClickedEvent()

  const handleClick = (contentfulId: any, typeName: any, platformLink: any, altText: any) => {
    sendLinkClickedEvent({
      entry_id: contentfulId,
      contentful_type: typeName,
      link_to: platformLink,
      link_text: altText ?? 'N/A',
    })
  }

  return (
    <BioContent styleOverrides={styleOverrides}>
      {isAuthorPage ? (
        <BioImageHeader image={gatsbyImageData} alt={alternateText} />
      ) : (
        <Link
          to={`/blog/author/${slugify(title.toLowerCase())}`}
          styleOverrides={profilePictureStyles}
          contentfulId={contentful_id}
          typeName={__typename}
        >
          <BioImageHeader image={gatsbyImageData} alt={alternateText} />
        </Link>
      )}
      <Content>
        <TextHeader>
          <Heading as="h5" styleOverrides={titleStyles}>
            {isAuthorPage ? (
              title
            ) : (
              <Link
                to={`/blog/author/${slugify(title.toLowerCase())}`}
                contentfulId={contentful_id}
                typeName={__typename}
              >
                {title}
              </Link>
            )}
          </Heading>

          {jobTitle && (
            <>
              <Separator as="h5" styleOverrides={titleStyles}>
                &nbsp;|&nbsp;
              </Separator>
              <Heading as="h5" styleOverrides={titleStyles}>
                {jobTitle}
              </Heading>
            </>
          )}
        </TextHeader>

        {socialLinks.length > 0 && (
          <SocialButtonsSection>
            {socialLinks.map(
              ({
                image: {
                  image: { gatsbyImageData },
                  alternateText: { alternateText: altText },
                  contentful_id: contentfulId,
                  __typename: typeName,
                },
                title,
                link: platformLink,
              }) => (
                <SocialButton
                  href={platformLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  key={title}
                  data-entry-id={contentfulId}
                  onClick={() => handleClick(contentfulId, typeName, platformLink, altText)}
                >
                  <SocialImage image={gatsbyImageData} alt={altText} />
                </SocialButton>
              ),
            )}
          </SocialButtonsSection>
        )}
        <TextContent>
          <Markdown withSuperscript contentfulId={contentful_id} typeName={__typename}>
            {bio}
          </Markdown>
        </TextContent>
      </Content>
    </BioContent>
  )
}
