import { useCallback } from 'react'
import { PageViewedEventProperties } from '../../types/analytics/analyticsEventProperties'
import { AnalyticsEvents } from '../../types/analytics/analyticsEvents.enum'
import { useSendGTMEvent } from './useSendGTMEvent'
import { useSendSegmentEvent } from './useSendSegmentEvent'

export const useSendPageViewedEvent = () => {
  const { sendSegmentPageEvent } = useSendSegmentEvent<PageViewedEventProperties>()
  const sendGTMEvent = useSendGTMEvent<PageViewedEventProperties>()

  const sendPageViewedEvent = useCallback(
    (contentful_id: string) => {
      const pageViewedEventProperties = { entry_id: contentful_id }
      sendSegmentPageEvent(pageViewedEventProperties)
      sendGTMEvent(AnalyticsEvents.PAGE_VIEWED, pageViewedEventProperties)
    },
    [sendSegmentPageEvent, sendGTMEvent],
  )

  return sendPageViewedEvent
}
