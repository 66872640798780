import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'

import { Heading, Markdown, Paragraph } from '../components/typography'
import { IBlogInfo } from '../graphql/modules/blog'
import { Link } from './Link'
import { useTheme } from '@emotion/react'
import { useSendLinkClickedEvent } from '../hooks/analytics/useSendLinkClickedEvent'

interface IBlog {
  blog: IBlogInfo
}

const BodySection = styled.div`
  ${mq({
    marginTop: 'auto',
  })}
`

const BlogContainer = styled.article`
  ${mq({
    display: 'flex',
    maxWidth: ['none', '340px', '434px'],
    flexBasis: ['100%', '50%', '100%'],
    flexWrap: 'wrap',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    margin: ['15px 0', '25px 0', '0 0 50px 0'],
    padding: ['0', '0 10px', '0 10px'],
    flexDirection: 'column',
    ':hover,:focus,:active': {
      cursor: 'pointer',
      [`a > span > h3, ${BodySection} > p:last-of-type`]: {
        color: COLORS.PRIMARY['500'],
      },
    },
  })}
`

const AuthorAndDate = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: ['5px 0', '', '10px 0'],
  })}
`

const TitleSection = styled.div`
  ${mq({
    marginBottom: 'auto',
  })}
`

// Styling the Img component to allow proper scaling using gatsby-image
const BlogImageHeader = styled(GatsbyImage)`
  ${mq({
    height: 'auto',
    borderRadius: '8px',
  })}
`

const blogTitleStyles = {
  color: COLORS.NEUTRAL.COOL['900'],
  textAlign: 'left',
}

const blogTitleLinkStyles = {
  display: 'block',
  margin: ['25px 0 15px 0', '25px 0 25px 0', '15px 0 25px 0'],
}

const blogParagraphStyles = {
  textAlign: 'left',
  margin: 0,
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'normal',
}

const authorStyles = {
  fontSize: ['12px', '', '14px'],
  fontWeight: 'bold',
  lineHeight: ['1.5', '', '1.43'],
  margin: '0',
}

const dateStyles = {
  fontSize: ['12px', '', '14px'],
  fontWeight: 'bold',
  lineHeight: ['1.5', '', '1.43'],
  margin: '0',
}

const linkTextStyles = {
  fontSize: ['16px', '18px', '18px'],
  lineHeight: ['2.13', '1.89', '1.44'],
  fontWeight: 'bold',
  color: COLORS.PURPLE['700'],
  textAlign: 'left',
  margin: '0',
}

const BlogPost: React.FC<IBlog> = ({ blog }) => {
  const {
    title,
    authorBio,
    date,
    text: { text },
    image: {
      alternateText: { alternateText: altText },
      image: { gatsbyImageData },
    },
    link: { linkText, link: linkPath, rel },
    contentful_id,
    __typename,
  } = blog

  const sendLinkClickedEvent = useSendLinkClickedEvent()

  const handleCardClick = () => {
    sendLinkClickedEvent({
      entry_id: contentful_id,
      contentful_type: __typename,
      link_to: linkPath,
      // TODO: Fix link_text here to consider the full card texts
      link_text: linkText ?? 'N/A',
    })

    navigate(linkPath)
  }

  const h5Styles = useTheme().h5

  return (
    <BlogContainer onClick={handleCardClick} data-entry-id={contentful_id}>
      <TitleSection>
        <BlogImageHeader image={gatsbyImageData} alt={altText} />
        <div onClick={event => event.stopPropagation()}>
          <Link
            to={linkPath}
            textStyles={h5Styles}
            styleOverrides={blogTitleLinkStyles}
            rel={rel}
            contentfulId={contentful_id}
            typeName={__typename}
          >
            <Heading as="h3" styledAs="h5" styleOverrides={{ ...blogTitleStyles, ...blogTitleLinkStyles }}>
              {title}
            </Heading>
          </Link>
        </div>
      </TitleSection>
      <BodySection>
        <Markdown
          customStyles={{ paragraph: blogParagraphStyles }}
          withSuperscript
          contentfulId={contentful_id}
          typeName={__typename}
        >
          {text}
        </Markdown>
        <AuthorAndDate>
          <Paragraph styleOverrides={authorStyles}>{authorBio?.title}</Paragraph>
          <Paragraph styleOverrides={dateStyles}>{date}</Paragraph>
        </AuthorAndDate>
        <Paragraph styleOverrides={linkTextStyles}>{linkText}</Paragraph>
      </BodySection>
    </BlogContainer>
  )
}

export default BlogPost
