import React, { FocusEventHandler } from 'react'
import styled from '@emotion/styled'
import facepaint from 'facepaint'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'
import clearIcon from '../images/search-clear.svg'

import { ChangeEventWithMocks, ICypressProps } from './commonTypes'
import { useTheme } from '@emotion/react'

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

interface IInputProps {
  id: string
  label: string
  isLabelScreenReaderOnly?: boolean
  value: string
  onChange?: (e: ChangeEventWithMocks) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  hasError?: boolean
  errorHint?: string
  icon?: React.ReactNode
  iconPosition?: `${IconPosition}`
  clearInputButton?: boolean
  isDarkTreatment?: boolean
  onClearInput?: (e?: ChangeEventWithMocks) => void
}

const Container = styled.div<{ hasError: boolean }>`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Lato',
    width: '100%',
    margin: '5px 0 8px 0',
    maxWidth: ['none', '528px', '855px'],
  })}

  &:focus-within > label {
    color: ${COLORS.PRIMARY['500']};
  }
  ${({ hasError }) =>
    hasError
      ? `& > span > div {
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 3px;
    right: 3px;
    background-color: transparent;
    border: 2px solid ${COLORS.RED['700']};
    border-radius: 8px;
  }`
      : `&:focus-within > span > div {
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 3px;
    right: 3px;
    background-color: transparent;
    border: 2px solid ${COLORS.PRIMARY.DEFAULT};
    border-radius: 8px;
  }`}
`

const InputContainer = styled.span`
  ${mq({
    display: 'flex',
    flex: '1',
    position: 'relative',
    alignItems: 'center',
    fontSize: ['16px', '16px', '20px'],
  })}
`

const Icon = styled.span<{ iconPosition: `${IconPosition}` }>`
  position: absolute;
  & img {
    margin: auto;
    padding: 0;
  }
  & :hover {
    cursor: pointer;
  }
  ${({ iconPosition }) => iconPosition === IconPosition.LEFT && `left: 16px;`}
  ${({ iconPosition }) => iconPosition === IconPosition.RIGHT && `right: 20px;`}
`

const StyledInput = styled.input<{
  hasError?: boolean
  iconPosition?: `${IconPosition}`
  isDarkTreatment: boolean
  inputThemeStyles: facepaint.BaseArg & {
    error?: facepaint.BaseArg | undefined
  }
}>(({ hasError, iconPosition, inputThemeStyles, isDarkTreatment }) => {
  const styles = inputThemeStyles
  const errorStyles = { ...styles, ...styles.error }
  let iconPadding = {}
  if (iconPosition) {
    iconPadding =
      iconPosition === IconPosition.LEFT
        ? { paddingLeft: '30px' }
        : { paddingRight: '45px' }
  }
  return mq({
    ...(hasError && !isDarkTreatment ? errorStyles : styles),
    ...iconPadding,
  })
})

const Label = styled.label<{ hasError?: boolean }>`
  ${mq({
    letterSpacing: '0.4px',
    marginTop: '5px',
  })}
  color: ${({ hasError }) => (hasError ? COLORS.RED['700'] : undefined)};
  &:focus,
  &:hover {
    color: ${COLORS.PRIMARY.DEFAULT};
  }
`

const ErrorHint = styled.span`
  ${mq({
    textAlign: 'left',
    height: 'fit-content',
    fontSize: ['12px', '16px', '16px'],
    fontStyle: 'italic',
    lineHeight: 1.5,
    color: COLORS.RED['700'],
  })}
`

const BorderBox = styled.div``

const ClearInputButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLSpanElement>
}) => {
  return (
    <Icon iconPosition={IconPosition.RIGHT} onClick={onClick}>
      <img src={clearIcon} alt="Clear search" />
    </Icon>
  )
}

export const Input: React.FC<
  IInputProps & ICypressProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  hasError = false,
  errorHint = 'Invalid',
  icon = '',
  iconPosition,
  children = null,
  clearInputButton = false,
  onClearInput,
  isLabelScreenReaderOnly,
  isDarkTreatment,
  ...otherInputProps
}) => {
  //Icon right cannot be shown at the same time as the clearInputButton (they occupy the same space)
  let showIcon = !!icon
  if (clearInputButton && iconPosition === IconPosition.RIGHT) showIcon = false

  const testId = id.replace(/\s/g, '')

  const inputThemeStyles = useTheme()[isDarkTreatment ? 'inputDark' : 'input']

  return (
    <Container hasError={!!hasError}>
      {!isLabelScreenReaderOnly && (
        <Label htmlFor={id} hasError={hasError} data-cy={`label-${testId}`}>
          {label}
        </Label>
      )}
      <InputContainer>
        {children || (
          <>
            {clearInputButton && (
              <ClearInputButton
                onClick={() => {
                  const emptyTextEvent = { target: { name: id, value: '' } }
                  if (typeof onClearInput === 'function') {
                    onClearInput(emptyTextEvent)
                  } else {
                    if (onChange) onChange(emptyTextEvent)
                  }
                }}
              />
            )}
            {showIcon && iconPosition && (
              <Icon iconPosition={iconPosition}>{icon}</Icon>
            )}
            <StyledInput
              id={id}
              name={id}
              data-cy={`input-${testId}`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              hasError={hasError}
              iconPosition={iconPosition}
              aria-label={isLabelScreenReaderOnly ? label : undefined}
              aria-invalid={hasError}
              aria-describedby={`${id}ErrorHint`}
              isDarkTreatment={!!isDarkTreatment}
              inputThemeStyles={inputThemeStyles}
              {...otherInputProps}
            />
            {!!isDarkTreatment && <BorderBox />}
          </>
        )}
      </InputContainer>
      <ErrorHint id={`${id}ErrorHint`} role="alert" data-cy={`error-${testId}`}>
        {hasError ? errorHint : ''}
      </ErrorHint>
    </Container>
  )
}
